<template>
  <div>
    <getecma-form v-if="vehicle" :submit="save">
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-input
            v-model="vehicle.license_plate"
            label="Placa*"
            name="placa do carro"
            type="text"
            rules="required"
            placeholder="ex.: LGE4X22" />
        </div>
      </div>
      <!--<div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <color-picker v-model="color" />
          <getecma-header size="sm" class="mt--xl mb--md">Cor Selecionada: </getecma-header>
          <svg height="32" width="32">
            <circle cx="16" cy="16" r="15" :fill="color" />
          </svg>
        </div>
      </div>-->
      <div>
          <getecma-header size="sm" class="mt--xl"> Modelo*</getecma-header>
      </div>
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-select
            name="modelo"
            rules="required"
            placeholder="Selecione o modelo"
            :value="modelSelected.name"
            :options="models"
            @on-change="onModelChange">
          </getecma-select>
        </div>
      </div>
      <div>
          <getecma-header size="sm" class="mt--xl"> Rotas*</getecma-header>
      </div>
      <div>
        <el-transfer
          v-model="selecteds"
          class="custom-transfer"
          filterable
          :filter-method="filterMethod"
          :titles="['Disponíveis', 'Selecionados']"
          :button-texts="['Remover', 'Adicionar']"
          filter-placeholder="Filtrar por rota"
          :data="regions">
        </el-transfer>
      </div>
      <div class="mt--xl mb--md d--flex justify-content-end">
        <getecma-button
          class="fs--xs"
          :round="false"
          size="lg"
          @click="goHistoryBack()">
          Cancelar
        </getecma-button>
        <getecma-button
          native-type="submit"
          class="fs--xs ms--md"
          :round="false"
          bg-color="success"
          size="lg">
          Salvar
        </getecma-button>
      </div>
    </getecma-form>
  </div>
</template>

<script>

// eslint-disable-next-line import/no-extraneous-dependencies
// import ColorPicker from 'vue-color-picker-wheel';
import { fetchRegions } from '@/modules/region/region.service';
import { toastError } from '@/services/toastService';
import { goHistoryBack } from '@/router/route.service';
import { getters } from '@/modules/user/user.store';
import { fetchModels, getModelById } from '@/modules/model/model.service';
import { fetchRegionsByVehicle } from '@/modules/vehicle/vehicle.service';

export default {
  name: 'GetecmaVehicleCreateInformation',
  components: {
    // ColorPicker,
  },
  inject: ['vehicleEditVm'],
  data() {
    return {
      vehicle: this.vehicleEditVm.vehicle,
      performer: getters.getUser(),
      models: [],
      regions: [],
      selecteds: [],
      modelSelected: null,
      color: this.vehicleEditVm.vehicle.color_hex,
      filterMethod(query, item) {
        return item.label && item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
      },
    };
  },
  async mounted() {
    await this.fetchModels();
    await this.fetchModel();
    await this.fetchRegions();
    await this.fetchRegionsByVehicle();
  },
  methods: {
    goHistoryBack,
    async fetchRegions() {
      try {
        const params = { limit: 'all', page: 1, search: '' };
        const response = await fetchRegions(params);
        this.regions = response.rows.map(region => ({
          key: region.id,
          label: region.name,
        }));
      } catch (error) {
        console.error('Erro ao buscar os modelos de veículos:', error);
      }
    },
    fetchRegionsByVehicle() {
      fetchRegionsByVehicle(this.vehicle.id)
        .then(response => {
          this.selecteds = response.rows.map(region => region.id);
        })
        .catch(() => toastError('Erro ao obter rotas por veículo'));
    },
    async fetchModel() {
      getModelById(this.vehicle.model_id)
        .then(data => {
          this.modelSelected = data;
        })
        .catch(() => toastError('Erro ao obter bairro por ID'));
    },
    async fetchModels() {
      try {
        const params = { limit: 'all', page: 1, search: '' };
        const response = await fetchModels(params);
        this.models = response.rows.map(model => ({
          key: model.id,
          value: model.name,
        }));
      } catch (error) {
        console.error('Erro ao buscar os modelos de veículos:', error);
      }
    },
    save() {
      if (this.selecteds.length === 0) {
        toastError('Você precisa selecionar pelo menos uma rota');
      } else {
        this.vehicle.color_hex = this.color;
        this.vehicle.regions = this.selecteds;
        this.$emit('save');
      }
    },
    onModelChange(model) {
      this.vehicle.model_id = model.key;
    },
  },
};
</script>
